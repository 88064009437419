import {
  DonationTypeUtil,
  OrganType,
  OrganTypeUtil,
  ProductType,
  ProductUtil,
  Reading,
  Session,
  SessionState,
  TimelineEntry,
  generateCsvDataFromExportables,
} from '@packages/firebase';
import { useTranslation } from '@packages/translations';
import { SessionTrackingMap } from '@packages/ui';
import { formatDate, millisecondsToTime } from '@packages/utils';
import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { MdDeleteOutline, MdEdit, MdOutlineDangerous, MdOutlineVpnKey, MdPinDrop, MdPlayCircleOutline, MdTimer } from 'react-icons/md';
import { useAuthUser } from '../../contexts/useAuthUser';
import {
  removeDestinationForSession,
  setDestinationForSession,
  useFetchDestination,
  useFetchDestinationsTmp,
} from '../../lib/firebase/firestore';
import Button from '../Shared/Button';
import Card from '../Shared/Card';
import ExportAction from '../Shared/ExportAction';
import Modal from '../Shared/Modal';
import SearchFilter from '../Shared/Table/filters/SearchFilter';
import TimelineItem from '../Shared/Timeline/TimelineItem';
import ETAPanel from './ETAPanel';
import IschemicTimePanel from './IschemicTimePanel';
import PressureDetails from './PressureDetails';
import PressureTimelineChart from './PressureTimelineChart';
import TemperatureDetails from './TemperatureDetails';
import TemperatureTimelineChart from './TemperatureTimelineChart';

export default function SessionTab({
  session,
  readings,
  locations,
  members,
  timeline,
}: {
  session: Session;
  readings: Reading[];
  locations: any;
  members: any;
  timeline: any;
}) {
  const { t } = useTranslation();

  const [sessionState, setSessionState] = useState<SessionState>(SessionState.CREATED);
  const [exportData, setExportData] = useState<any>();
  const [latestTimelineEvent, setLatestTimelineEvent] = useState<TimelineEntry>();

  const { destination } = useFetchDestination(session.destination);

  const [isSetDestinationModalOpen, setIsSetDestinationModalOpen] = useState(false);

  useEffect(() => {
    if (session == null) {
      return;
    }

    setSessionState(session.getState());
  }, [session]);

  useEffect(() => {
    if (timeline == null || timeline.length === 0) {
      return;
    }

    setLatestTimelineEvent(timeline[timeline.length - 1] as TimelineEntry);
  }, [timeline]);

  useEffect(() => {
    if (session == null) {
      return;
    }

    const { columns, data } = generateCsvDataFromExportables(session, readings, timeline);

    setExportData({
      filename: `paragonix_${session.joinKey}.csv`,
      columns,
      data,
    });
  }, [session, readings, timeline, t]);

  const startedEvent = readings?.find(reading => reading?.isStartedEvent());
  const stoppedEvent = readings?.find(reading => reading?.isStoppedEvent());

  const sessionList = [
    {
      title: t('session_panel_key_title'),
      value: session.joinKey,
      Icon: MdOutlineVpnKey,
    },
    {
      title: t('session_panel_started_title'),
      value: session.createdAt != null ? formatDate(session?.createdAt) : '-',
      Icon: MdPlayCircleOutline,
    },
    {
      title: t('session_panel_stopped_title'),
      value: session.endedAt != null ? formatDate(session?.endedAt) : '-',
      Icon: MdOutlineDangerous,
    },
    {
      title: t('duration'),
      value: session.durationString({ seconds: false }),
      Icon: MdTimer,
    },
  ];

  const loggerList = [
    {
      title: t('logger_panel_serial_number_title'),
      value: session.loggerSerialNo,
      Icon: MdOutlineVpnKey,
    },
    {
      title: t('logger_panel_started_title'),
      value: startedEvent?.timestamp != null ? formatDate(startedEvent.timestamp) : '-',
      Icon: MdPlayCircleOutline,
    },
    {
      title: t('logger_panel_stopped_title'),
      value: stoppedEvent?.timestamp != null ? formatDate(stoppedEvent.timestamp) : '-',
      Icon: MdOutlineDangerous,
    },
    {
      title: t('duration'),
      value:
        startedEvent?.timestamp != null && stoppedEvent?.timestamp != null
          ? millisecondsToTime(stoppedEvent.timestamp.getTime() - startedEvent.timestamp.getTime(), { seconds: false })
          : '-',
      Icon: MdTimer,
    },
  ];

  // if (sessionState === SessionState.CREATED)
  //   return (
  //     <Card>
  //       <div className="flex flex-col items-center py-16 text-center">
  //         <img src={ProductUtil.imagePath(session.product)} alt="" style={{ width: '300px' }} />
  //         <div className="my-5 text-xl font-bold">{t('start_logger_title')}</div>
  //         <div>{t('start_logger_text')}</div>
  //       </div>
  //     </Card>
  //   );

  return (
    <>
      <div className="mb-5 grid grid-cols-1 gap-5 xl:grid-cols-2">
        <Card title={t('generic_information_title')}>
          <ul>
            <li className="flex items-center">
              <div className="bg-product-light text-product white flex h-[54px] w-[54px] items-center justify-center rounded">
                <img src={OrganTypeUtil.iconPath(session.organ, false)} alt="" height="26px" width="26px" />
              </div>

              <div className="ml-4">
                <p className="text-muted text-[13px]"> {t('product_type')}</p>
                <p className="text-dark text-[16px] font-medium">{`Paragonix ${ProductUtil.toHeading(
                  session.product,
                )} [${DonationTypeUtil.toUIString(session.donationType)}]`}</p>
                <p className="text-dark">Donor ID: {session.unosId ?? 'Not set'}</p>
              </div>
            </li>
          </ul>
        </Card>

        {(sessionState === SessionState.LOGGER_STOPPED || sessionState === SessionState.FINISHED) && (
          <Card title={t('downloads')}>
            {exportData && <ExportAction filename={exportData.filename} columns={exportData.columns} data={exportData.data} />}
          </Card>
        )}

        {latestTimelineEvent != null && sessionState !== SessionState.LOGGER_STOPPED && sessionState !== SessionState.FINISHED && (
          <Card title={t('session_latest_timeline_event_title')}>
            <div className="mt-5 flex">
              <TimelineItem event={latestTimelineEvent} product={session.product} />
            </div>
          </Card>
        )}
      </div>

      <div className="mb-5 grid grid-cols-1 gap-5 xl:grid-cols-2">
        <Card
          title={
            <div className="flex items-center space-x-2.5">
              <span>Recipient Transplant Center</span>

              {!session.finished && (
                <button type="button" className="rounded-full bg-black p-1 text-white" onClick={() => setIsSetDestinationModalOpen(true)}>
                  <MdEdit className="h-3.5 w-3.5" />
                </button>
              )}
            </div>
          }
        >
          <div>
            <ul>
              <li className="flex items-center py-4">
                <div className="bg-product-light text-product white flex h-[54px] w-[54px] items-center justify-center rounded">
                  <MdPinDrop className="h-7 w-7" />
                </div>

                <div className="ml-4">
                  <p className="text-dark text-[16px] font-[500]">{destination?.name ?? 'Not set'}</p>
                  <p className="text-muted text-sm">{destination?.handle ?? '-'}</p>
                </div>
              </li>
            </ul>
          </div>
        </Card>

        <ETAPanel session={session} members={members} />
      </div>

      <div className="mb-5 grid auto-rows-max gap-5 md:grid-cols-12">
        {/* Temperature Details */}
        <div
          className={classNames(
            { 'lg:col-span-4 xl:col-span-5': session.product === ProductType.BAROGUARD },
            { 'xl:col-span-6': session.product !== ProductType.BAROGUARD },
            'order-1 col-span-12',
          )}
        >
          <TemperatureDetails session={session} />
        </div>

        {/* Pressure Details */}
        {session.product === ProductType.BAROGUARD && (
          <div
            className={classNames(
              { block: session.product === ProductType.BAROGUARD },
              { hidden: session.product !== ProductType.BAROGUARD },
              'order-2 col-span-12 lg:col-span-4 xl:col-span-5',
            )}
          >
            <PressureDetails session={session} />
          </div>
        )}

        {/* Ischemic Time */}
        <div
          className={classNames(
            { 'lg:col-span-4 xl:col-span-2': session.product === ProductType.BAROGUARD },
            { 'xl:col-span-6': session.product !== ProductType.BAROGUARD },
            'order-3 col-span-12',
          )}
        >
          <Card title={t('ischemic_time_title')} centered>
            <IschemicTimePanel ischemicTime={session.ischemicTime} product={session.product} />
          </Card>
        </div>
      </div>

      <div className="grid auto-rows-max gap-5 md:grid-cols-2">
        {/* Temperature Timeline */}
        <div
          className={classNames(
            { 'col-span-2 xl:col-span-1': session.product === ProductType.BAROGUARD },
            { 'col-span-2 xl:col-span-2': session.product !== ProductType.BAROGUARD },
            'order-3',
          )}
        >
          <Card title={t('session_temperature_timeline_title')}>
            <TemperatureTimelineChart title={t('session_temperature_timeline_title')} readings={readings as Reading[]} />
          </Card>
        </div>

        {/* Pressure Timeline */}
        {session.product === ProductType.BAROGUARD && (
          <div
            className={classNames(
              { 'col-span-2 xl:col-span-1': session.product === ProductType.BAROGUARD },
              { 'col-span-2 xl:col-span-1': session.product !== ProductType.BAROGUARD },
              'order-3',
            )}
          >
            <Card title={t('session_pressure_timeline_title')}>
              <PressureTimelineChart title={t('session_pressure_timeline_title')} readings={readings as Reading[]} />
            </Card>
          </div>
        )}

        {/* Live Map */}
        {sessionState !== SessionState.FINISHED && (
          <div className="order-4 col-span-2 xl:col-span-2">
            <Card title={t('live_session_tracking')}>
              <SessionTrackingMap
                sessions={[{ session, locations }]}
                options={{ showInfoWindows: false, height: '500px', showPolylines: process.env.REACT_APP_ENVIRONMENT === 'development' }}
              />
            </Card>
          </div>
        )}

        {/* Session & Logger Details */}
        <div className="order-5 col-span-2 xl:col-span-1">
          <Card title={t('session')}>
            <VerticalList list={sessionList} />
          </Card>
        </div>
        <div className="order-6 col-span-2 xl:col-span-1">
          <Card title={t('logger')}>
            <VerticalList list={loggerList} />
          </Card>
        </div>
      </div>

      <SetDestinationModal
        sessionId={session.id}
        handle={session.destination}
        organ={session.organ}
        isOpen={isSetDestinationModalOpen}
        setIsOpen={value => setIsSetDestinationModalOpen(value)}
      />
    </>
  );
}

function VerticalList({ list }) {
  return (
    <ul className="divide-y divide-gray-200">
      {list.map(item => (
        <li key={item.title} className="flex items-center py-4">
          <div className="bg-product-light text-product white flex h-[54px] w-[54px] items-center justify-center rounded">
            <item.Icon className="h-7 w-7" />
          </div>

          <div className="ml-4">
            <p className="text-muted text-[13px]">{item.title}</p>
            <p className="text-dark text-[16px] font-medium">{item.value}</p>
          </div>
        </li>
      ))}
    </ul>
  );
}

const SetDestinationModal = ({
  sessionId,
  handle,
  organ,
  isOpen,
  setIsOpen,
}: {
  sessionId: string;
  handle?: string;
  organ: OrganType;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}) => {
  const { user } = useAuthUser();

  const { destinations, isLoading } = useFetchDestinationsTmp(organ);

  const [search, setSearch] = useState<string>('');
  const [selectedDestinationHandle, setSelectedDestinationHandle] = useState<string | undefined>(handle);

  const filteredOptions = useMemo(() => {
    if (destinations == null || isLoading) {
      return [];
    }

    const sorted = destinations.sort((a, b) => a.name.localeCompare(b.name));

    if (search == null || search.length === 0) {
      return sorted;
    }

    return sorted.filter(
      option => option.name.toLowerCase().includes(search.toLowerCase()) || option.handle.toLowerCase().includes(search.toLowerCase()),
    );
  }, [destinations, search]);

  const recentDestinations = useMemo(() => {
    if (destinations == null || isLoading) {
      return [];
    }

    return destinations.filter(destination => user?.recentDestinations?.includes(destination.handle)).slice(-3);
  }, [destinations, user?.recentDestinations]);

  async function onSetDestination() {
    if (selectedDestinationHandle == null) {
      return;
    }

    await setDestinationForSession(sessionId, selectedDestinationHandle);
    setIsOpen(false);
  }

  async function onRemoveDestination() {
    await removeDestinationForSession(sessionId);
    setIsOpen(false);
  }

  return (
    <Modal size="xl" isOpen={isOpen} setIsOpen={() => setIsOpen(false)}>
      <div className="mb-8 flex items-start justify-between border-b px-5 py-5">
        <span className="text-[16px] font-[500]">Edit Recipient Transplant Center</span>

        {handle != null && (
          <button type="button" onClick={() => onRemoveDestination()}>
            <MdDeleteOutline className="h-7 w-7 text-black" />
          </button>
        )}
      </div>

      <div className="space-y-4 px-5 pb-5">
        <SearchFilter onChange={event => setSearch(event?.target?.value)} placeholder="Search..." />

        <hr />

        <div>
          {isLoading ? (
            <div className="flex h-[500px] w-full items-center justify-center rounded border">
              <div className="spinner-border inline-block h-7 w-7 animate-spin rounded-full border-2" role="status" />
            </div>
          ) : (
            <div className="h-[500px] w-full space-y-2.5 overflow-y-scroll">
              {recentDestinations.length > 0 && (search == null || search.length === 0) && (
                <>
                  <div>Recent</div>

                  {recentDestinations.map((option, index) => (
                    <button
                      type="button"
                      key={`org-option-${index}`}
                      onClick={() => setSelectedDestinationHandle(option.handle)}
                      className={classNames(
                        selectedDestinationHandle === option.handle ? 'border-success-500 border-2' : 'border',
                        'block w-full rounded py-1.5 px-2.5 text-left hover:bg-gray-50',
                      )}
                    >
                      <p>{option.name}</p>
                      <p className="text-muted text-sm">{option.handle}</p>
                    </button>
                  ))}
                </>
              )}

              <div>{search == null || search.length === 0 ? 'All' : 'Search Results'}</div>

              {filteredOptions.length > 0 ? (
                filteredOptions.map((option, index) => (
                  <button
                    type="button"
                    key={`org-option-${index}`}
                    onClick={() => setSelectedDestinationHandle(option.handle)}
                    className={classNames(
                      selectedDestinationHandle === option.handle ? 'border-success-500 border-2' : 'border',
                      'block w-full rounded py-1.5 px-2.5 text-left hover:bg-gray-50',
                    )}
                  >
                    <p>{option.name}</p>
                    <p className="text-muted text-sm">{option.handle}</p>
                  </button>
                ))
              ) : (
                <div className="py-2 text-center">
                  <i>No Recipient Transplant Centers found</i>
                </div>
              )}
            </div>
          )}
        </div>

        <hr />

        <Button className="w-full" disabled={selectedDestinationHandle == null} onClick={() => onSetDestination()}>
          Confirm
        </Button>

        <Button className="w-full" variant="outline" onClick={() => setIsOpen(false)}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
};
