import { ProductType, TimelineEntry, TimelineEntryTypeUtil } from '@packages/firebase';
import { timelineEntryIcon } from '@packages/ui';
import { formatDate } from '@packages/utils';
import classNames from 'classnames';
import { MdCircle } from 'react-icons/md';

interface TimelineItemProps {
  event: TimelineEntry;
  product: ProductType;
  withCircle?: boolean;
}

export default function TimelineItem({ event, product, withCircle }: TimelineItemProps) {
  return (
    <div className={classNames({ '-ml-[8px]': withCircle }, 'flex items-center')}>
      {withCircle && <MdCircle className="mr-5 text-[#495057]" />}
      <EventIcon type={event.type} />

      <div className="ml-5">
        <p className="text-dark text-[16px] font-[500]">{TimelineEntryTypeUtil.toUIString(event.type, product, event.annotations)}</p>
        <p className="text-muted ">{formatDate(event.timestamp)}</p>
      </div>
    </div>
  );
}

const EventIcon = ({ type }) => {
  const Icon = timelineEntryIcon(type);

  return (
    <div className="bg-product-light text-product white flex h-[54px] w-[54px] items-center justify-center rounded">
      <Icon />
    </div>
  );
};
