import { Routes } from '@packages/constants';
import { DonationType, ProductType, ProductUtil, SessionState, SessionType } from '@packages/firebase';
import { useTranslation } from '@packages/translations';
import { useMemo, VFC } from 'react';
import { HiOutlineChevronLeft, HiOutlineChevronRight } from 'react-icons/hi';
import { MdOutlineAddCircle } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { Column, useTable } from 'react-table';
import Button from '../Button';
import configureTable, { ConfigureTablePluginOptions } from './configureTable';
import ExportSelect from './export/ExportSelect';
import SearchFilter from './filters/SearchFilter';
import SelectFilter from './filters/SelectFilter';
import SortToggle from './SortToggle';

export type TableStyleTypes = 'card' | 'default';

export type TableProps<D extends {}> = {
  columns: Column<D>[];
  data: D[];
  options?: ConfigureTablePluginOptions;
};

const Table: VFC<TableProps<any>> = ({ columns, data, options }) => {
  const { t } = useTranslation();

  const { tableOptions, tablePlugins } = configureTable(columns, data, options);
  const table = useTable(tableOptions, ...tablePlugins);

  const from = useMemo(() => table.state.pageSize * table.state.pageIndex + 1, [table.state.pageIndex, table.state.pageSize]);

  const to = useMemo(
    () =>
      table.state.pageSize * table.state.pageIndex + table.state.pageSize < table.rows.length
        ? table.state.pageSize * table.state.pageIndex + table.state.pageSize
        : table.rows.length,
    [table.rows.length, table.state.pageIndex, table.state.pageSize],
  );

  const total = useMemo(() => table.rows.length, [table.rows.length]);

  return (
    <>
      {options?.hasFilters && (
        <div className="my-5 flex flex-row justify-between">
          <div className="flex flex-1 flex-row flex-wrap gap-3">
            <SearchFilter
              onChange={event => {
                table.setFilter('joinKey', event.target.value);
              }}
            />
            <SelectFilter
              label={t('session_state')}
              values={[
                { value: null, title: '-' },
                { value: SessionState.CREATED, title: t('session_state_created'), badge: 'neutral' },
                { value: SessionState.LOGGER_STARTED, title: t('session_state_logger_started'), badge: 'neutral' },
                { value: SessionState.LOGGER_STOPPED, title: t('session_state_logger_stopped'), badge: 'neutral' },
                { value: SessionState.FINISHED, title: t('session_state_finished'), badge: 'neutral' },
              ]}
              onChange={state => {
                table.setFilter('sessionState', state);
              }}
            />
            <SelectFilter
              label={t('organ')}
              values={[
                { value: null, title: '-' },
                { value: ProductType.LUNGGUARD, title: ProductUtil.organString(ProductType.LUNGGUARD), badge: 'neutral' },
                { value: ProductType.BAROGUARD, title: ProductUtil.organString(ProductType.BAROGUARD), badge: 'neutral' },
                { value: ProductType.SHERPAPAK, title: ProductUtil.organString(ProductType.SHERPAPAK), badge: 'neutral' },
                { value: ProductType.LIVERGUARD, title: ProductUtil.organString(ProductType.LIVERGUARD), badge: 'neutral' },
                { value: ProductType.PANCREASPAK, title: ProductUtil.organString(ProductType.PANCREASPAK), badge: 'neutral' },
                { value: ProductType.KTS, title: ProductUtil.organString(ProductType.KTS), badge: 'neutral' },
              ]}
              onChange={state => {
                table.setFilter('product', state);
              }}
            />
            <SelectFilter
              label={t('donation_type')}
              values={[
                { value: null, title: '-' },
                { value: DonationType.DBD, title: t('dbd'), badge: 'neutral' },
                { value: DonationType.DCD, title: t('dcd'), badge: 'neutral' },
              ]}
              onChange={state => {
                table.setFilter('donationType', state);
              }}
            />
            <SelectFilter
              label={t('session_type')}
              values={[
                { value: null, title: '-' },
                { value: SessionType.CLINICAL, title: t('session_type_clinical'), badge: 'neutral' },
                { value: SessionType.TRAINING, title: t('session_type_training'), badge: 'neutral' },
              ]}
              onChange={state => {
                table.setFilter('sessionType', state);
              }}
            />
            <ExportSelect selectedRows={table.selectedFlatRows} />
          </div>
          <div>
            <Link to={Routes.web.JOIN_NO_KEY}>
              <Button>
                <MdOutlineAddCircle className="h-5 w-5" />
                {t('join_session')}
              </Button>
            </Link>
          </div>
        </div>
      )}

      <div className="overflow-hidden overflow-x-auto">
        <div className="inline-block min-w-full py-2 align-middle">
          <div className="overflow-scroll">
            <table className="min-w-full">
              <thead className="mb-1 bg-gray-200">
                {table.headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <th
                        scope="col"
                        className="py-3.5 pl-2 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-4"
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                      >
                        <div className="flex items-center space-x-1">
                          <span className="text-base">{column.render('Header')}</span>
                          <span>
                            <SortToggle isSorted={column.isSorted} isSortedDesc={column.isSortedDesc} />
                          </span>
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody className="bg-white" {...table.getTableBodyProps()}>
                {(options?.hasPagination ? table.page : table.rows).map(row => {
                  table.prepareRow(row);

                  return (
                    <tr className="border-b border-gray-100 hover:bg-gray-50" {...row.getRowProps()}>
                      {row.cells.map(cell => {
                        return (
                          <td className="whitespace-nowrap px-4 py-4 text-gray-500" {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          {options?.hasPagination && (
            <nav className="flex w-full items-center justify-between bg-transparent py-3">
              <div className="hidden sm:block">
                <p className="text-sm text-gray-400">
                  Showing results {from} to {to} of {total}
                </p>
              </div>

              <div className="flex flex-1 justify-between space-x-6 sm:justify-end">
                <div className="space-x-3">
                  <span className="text-sm text-gray-400">Number of results</span>
                  <select
                    className="mt-1 rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                    value={table.pageSize}
                    onChange={event => {
                      table.setPageSize(Number(event.target.value));
                    }}
                  >
                    {[10, 20, 30, 40, 50].map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        {pageSize}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="flex items-center space-x-4">
                  <button
                    type="button"
                    className="relative inline-flex items-center text-gray-500 disabled:text-gray-300"
                    disabled={!table.canPreviousPage}
                    onClick={() => table.previousPage()}
                  >
                    <HiOutlineChevronLeft className="h-6" />
                  </button>
                  <button
                    type="button"
                    className="relative inline-flex items-center text-gray-500 disabled:text-gray-300"
                    disabled={!table.canNextPage}
                    onClick={() => table.nextPage()}
                  >
                    <HiOutlineChevronRight className="h-6" />
                  </button>
                </div>
              </div>
            </nav>
          )}
        </div>
      </div>
    </>
  );
};

export default Table;
